<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-form @submit.prevent="saveForm" method="post" id="course-form">
      <v-card>
        <v-card-title class="headline">
          {{ isEditing ? "Edit" : "Add a new" }} Course
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Sub Heading"
            v-model="fields.sub_heading"
            type="text"
            outlined
            :error="errors.hasOwnProperty('sub_heading')"
            :error-messages="errors['sub_heading']"
          ></v-text-field>
          <v-textarea
            label="Short Description *"
            v-model="fields.description"
            outlined
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <v-textarea
            label="Full Description"
            v-model="fields.full_description"
            outlined
            :error="errors.hasOwnProperty('full_description')"
            :error-messages="errors['full_description']"
          ></v-textarea>
          <div class="v-filepond-input">
            <v-input
              :error="errors.hasOwnProperty('image')"
              :error-messages="errors['image']"
            >
              <file-pond
                v-bind:required="true"
                name="image"
                ref="image-upload"
                :server="server"
                accepted-file-types="image/jpeg, image/png"
                credits="false"
                maxFiles="1"
                imagePreviewHeight="350"
                imageCropAspectRatio="1:1"
                imageResizeTargetWidth="350"
                imageResizeTargetHeight="350"
                :onaddfile="onaddfile"
                labelIdle='<b>Course Image *</b><br />Drag & Drop your image file or <span class="filepond--label-action"> Browse </span>'
              />
            </v-input>
          </div>
          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            prepend-inner-icon="mdi-currency-gbp"
            outlined
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export default {
  components: {
    FilePond,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      course: {},
      imageHasChanged: false,
      fields: {
        name: null,
        description: null,
        price: null,
        image: null,
        sub_heading: null,
        full_description: null,
      },
      server: {
        process: null,
        revert: null,
        remove: null,
      },
    };
  },

  methods: {
    openForm: function (course = null) {
      if (course !== null) {
        this.isEditing = true;
        this.course = course;
        this.fields.name = course.name;
        this.fields.description = course.description;
        this.fields.full_description = course.full_description;
        this.fields.price = course.price;
        this.fields.sub_heading = course.sub_heading;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.errors = {};
      this.course = {};
      this.imageHasChanged = false;
      this.fields = {
        name: null,
        description: null,
        full_description: null,
        price: null,
        image: null,
        sub_heading: null,
      };
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.courseId = this.course.id;
      }

      let formData = new FormData();
      formData.append("name", this.fields.name ?? "");
      formData.append("description", this.fields.description ?? "");
      formData.append("full_description", this.fields.full_description ?? "");
      formData.append("price", this.fields.price ?? "");
      formData.append("sub_heading", this.fields.sub_heading ?? "");

      if (this.hasFile()) {
        formData.append("image", this.fields.image);
      }

      this.$store
        .dispatch("flightmode/courses/saveCourse", {
          formData,
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    hasFile() {
      if (this.isEditing === true && this.imageHasChanged === false) {
        return false;
      }

      return this.fields.image !== null;
    },

    onaddfile(error, file) {
      if (error) {
        return;
      }

      if (this.isEditing) {
        this.imageHasChanged = true;
      }

      this.fields.image = file.file;
    },
  },
};
</script>
