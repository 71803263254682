var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Courses")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Courses","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","to":{ name: 'module-flightmode-courses-archived' }}},[_vm._v("View Old Courses")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","color":"accent"},on:{"click":function($event){return _vm.$refs.courseDialog.openForm()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add New Course")])])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.courses,"no-data-text":"No Courses found"},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-flightmode-courses-view',
            params: { courseId: item.id },
          }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.price",fn:function({ item }){return [_vm._v(" £"+_vm._s(item.price)+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-flightmode-courses-view',
                params: { courseId: item.id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(item)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}])})],1),_c('CourseDialog',{ref:"courseDialog"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog.open),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "open", $$v)},expression:"deleteDialog.open"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Course")]),_c('v-card-text',[_vm._v("Are you sure you want to archive "+_vm._s(_vm.deleteDialog.course.name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteDialog.loading},on:{"click":_vm.saveDelete}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }